@font-face {
    font-family: 'Museo Sans Regular';
    src: url('MuseoSansCyrl-500.eot');
    src: url('MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'), url('MuseoSansCyrl-500.woff2') format('woff2'), url('MuseoSansCyrl-500.woff') format('woff'), url('MuseoSansCyrl-500.ttf') format('truetype'), url('MuseoSansCyrl-500.svg#MuseoSansCyrl-500') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo Sans Bold';
    src: url('MuseoSansCyrl-700.eot');
    src: url('MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'), url('MuseoSansCyrl-700.woff2') format('woff2'), url('MuseoSansCyrl-700.woff') format('woff'), url('MuseoSansCyrl-700.ttf') format('truetype'), url('MuseoSansCyrl-700.svg#MuseoSansCyrl-700') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap
}
